export const links = [
    {
    title: 'CONTACT',
    links: [
      // {
      //   label: 'XXX-XXX-XXX',
      //   href: '#',
      // },
      {
        label: 'contact@mediconnect.co',
        href: 'mailto:contact@mediconnect.co',
      },
      {
        label: 'MDCONNECT DMCC <br>902-27, Reef Tower<br> Cluster O<br> Jumeirah Lake Towers <br> Dubai, UAE',
        href: '#',
      },
    ],
  },
  {
    title: 'PAGES',
    links: [
      {
        label: 'Insights',
        href: '/insights',
      },
      {
        label: 'About Us',
        href: '/about',
      },
      {
        label: 'Contact',
        href: '/contact',
      },
       {
        label: 'Privacy Policy',
        href: '/privacy-policy',
      },
    ],
  },

  {
    title: 'SOCIAL',
    links: [
      {
        label: 'Linkedin',
        href: 'https://www.linkedin.com/company/mediconnectco/',
      },
      {
        label: 'Facebook',
        href: 'https://www.facebook.com/profile.php?id=100089324151962',
      },
   ,
    ],
  },
  

]