import "./css/style.css"

import { ChakraProvider } from '@chakra-ui/react'
import Fonts from './components/base/fonts'
import Footer from "./components/footer/footer"
import FooterNew from './components/footer/footer_new'
import {Header} from "./components/header/header"
import { Helmet } from 'react-helmet';
import React from "react"
import { theme } from './theme/theme'

const Layout = ({ children }) => {

    // if (typeof window !== 'undefined') {
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag(){dataLayer.push(arguments);}
    //   gtag('js', new Date());
    //   gtag('config', 'G-XQLYJJG0PL');
    // }

  return (
    <>
      <Helmet htmlAttributes={{ lang : 'en' }}>
        <meta name="google-site-verification" content="6eANILfMotcGfPVUsrdZz1A8HjAj5qvLn1fNzFcfPME" /> 
        <meta name="description" content="Statys Connect is a business ecosystem of IT and other services created specifically for medical and pharmaceutical professionals."/>
        <meta property="og:image" content="src/images/favicon-32x32.png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:width" content="50" />
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-XQLYJJG0PL"></script> */}
      </Helmet>
     <ChakraProvider theme={theme}>
        <Fonts/> 
        <Header></Header>
        <main>{children}</main>
        <FooterNew></FooterNew>
        {/* <Footer> </Footer> */}
      </ChakraProvider>
    </>
  )
}

export default Layout
